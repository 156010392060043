class CookieManager {
  #cookieLevel;
  #functionalCookie;
  #thirdPartyCookie;
  #globalObserver;

  // We have to initiate the cookie-level (let's set it to -1 since we're not sure if we care about cookies yet).
  // We also have to set the functional- and third-party-cookie-levels. Remember that we're using bitwise-operators
  // to check which level is OK.
  constructor() {
    this.#cookieLevel = -1;
    this.#functionalCookie = 2;
    this.#thirdPartyCookie = 4;
    this.#globalObserver = null;
  }

  // Used to make sure we're allowing all cookies if "showCookieNotice" is set to false in
  // (or missing from) the map-configuration. This method should only be run once.
  setConfig = (settings) => {
    const { showCookieNotice, globalObserver } = settings;
    // If "showCookieNotice" is set to true in the map-configuration we'll check if the user has
    // interacted with the cookie-notice earlier. (If they did, a cookie-level will be set in LS).
    if (showCookieNotice) {
      this.setLevel(this.#getStoredCookieLevel());
      this.#globalObserver = globalObserver;
    } else {
      // Otherwise we set the level to -1, stating that all cookies are allowed, and that the cookie-notice
      // shouldn't be shown.
      this.#cookieLevel = -1;
    }
  };

  // Updates the cookie-level (both internally and in the store). It also emits an event on the
  // globalObserver so that the components can re-render when the level is changed.
  setLevel = (level) => {
    this.#cookieLevel = level;
    this.#globalObserver &&
      this.#globalObserver.publish("core.cookieLevelChanged", level);
    window.localStorage.setItem("cookieLevel", this.#cookieLevel);
  };

  // Returns wether the cookie-notice should be shown or not.
  shouldShowNotice = () => {
    return this.#cookieLevelNotSet();
  };

  // Returns wether the cookie-level has been set yet or not.
  #cookieLevelNotSet = () => {
    return this.#cookieLevel === 0;
  };

  // Returns wether any cookies are allowed or not. Remember, if the cookie-level is set to -1
  // we don't care about cookies at all in this application. (This is set in the map-configuration).
  #allCookiesAllowed = () => {
    return this.#cookieLevel === -1;
  };

  // Returns potentially stored cookie-level from LS. If nothing is set in LS, we return 0 (stating that
  // the cookie-level has not been set yet).
  #getStoredCookieLevel = () => {
    const levelInStorage = window.localStorage.getItem("cookieLevel");
    return levelInStorage ? parseInt(levelInStorage) : 0;
  };

  // Returns wether functional-cookies are OK to store or not.
  functionalOk = () => {
    // If the cookie-level is not set yet, we cannot store them.
    if (this.#cookieLevelNotSet()) {
      return false;
    }
    // If we don't care about cookies, we can do whatever we want.
    if (this.#allCookiesAllowed()) {
      return true;
    }
    // Otherwise we do a bitwise-comparison between the current cookie-level and a functional-
    // cookie-level. Remember that if both functional- and third-party-cookies are allowed (level 7)
    // this will return true, since 0111 & 0010 => 0010.
    return (
      (this.#cookieLevel & this.#functionalCookie) === this.#functionalCookie
    );
  };

  // Returns wether third-party-cookies are OK to store or not.
  thirdPartyOk = () => {
    // If the cookie-level is not set yet, we cannot store them.
    if (this.#cookieLevelNotSet()) {
      return false;
    }
    // If we don't care about cookies, we can do whatever we want.
    if (this.#allCookiesAllowed()) {
      return true;
    }
    // Otherwise we do a bitwise-comparison between the current cookie-level and a third-party-
    // cookie-level. Remember that if both functional- and third-party-cookies are allowed (level 7)
    // this will return true, since 0111 & 0100 => 0100.
    return (
      (this.#cookieLevel & this.#thirdPartyCookie) === this.#thirdPartyCookie
    );
  };
}

// Initiate the singleton. Note: it never gets exported from this module.
const CM = new CookieManager();
// Setup the exports that allow us to modify certain aspects of the singleton-instance.
// Usage: e.g. import { setLevel } from "./Cookie";
export const setConfig = (settings) => CM.setConfig(settings);
export const setLevel = (level) => CM.setLevel(level);
export const shouldShowNotice = () => CM.shouldShowNotice();
export const functionalOk = () => CM.functionalOk();
export const thirdPartyOk = () => CM.thirdPartyOk();
