import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";

import LegacyNonMarkdownRenderer from "./LegacyNonMarkdownRenderer";
import { customComponentsForReactMarkdown } from "../../utils/customComponentsForReactMarkdown";

export default function ResponsiveDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const {
    children,
    onAbort,
    onClose,
    open,
    options: {
      abortText,
      allowDangerousHtml,
      buttonText,
      headerText,
      primaryButtonVariant,
      prompt,
      text,
      useLegacyNonMarkdownRenderer,
    },
  } = props;
  const [promptText, setPromptText] = useState("");

  const rehypePlugins = allowDangerousHtml === true ? [rehypeRaw] : [];

  const handleAbort = () => {
    onAbort(promptText);
  };

  const handleClose = () => {
    onClose(promptText);
  };

  return (
    <Dialog
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      {headerText && (
        <DialogTitle id="responsive-dialog-title">{headerText}</DialogTitle>
      )}
      <DialogContent>
        {children}
        {useLegacyNonMarkdownRenderer === true ? (
          <LegacyNonMarkdownRenderer text={text} />
        ) : (
          <ReactMarkdown
            remarkPlugins={[gfm]}
            rehypePlugins={rehypePlugins}
            components={customComponentsForReactMarkdown}
            children={text}
          />
        )}

        {prompt && (
          <form
            noValidate
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              props.onClose(promptText);
              return false;
            }}
          >
            <TextField
              id="prompt-text"
              label=""
              value={promptText}
              onChange={(e) => {
                setPromptText(e.target.value);
              }}
              margin="normal"
              autoFocus={true}
            />
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant={primaryButtonVariant || "text"}>
          {buttonText}
        </Button>
        {abortText && (
          <Button onClick={handleAbort} sx={{ color: "text.primary" }}>
            {abortText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
