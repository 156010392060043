import "react-app-polyfill/stable";

import "elm-pep";
import "core-js/features/promise/all-settled";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/material-icons";
import "ol/ol.css";
import "./custom-ol.css";
import React from "react";
import { createRoot } from "react-dom/client";
import buildConfig from "./buildConfig.json";
import StartupError from "./components/Errors/StartupError";
import WebMAPThemeProvider from "./components/WebMAPThemeProvider";
import { initHFetch, hfetch, initFetchWrapper } from "./utils/FetchWrapper";
import LocalStorageHelper from "./utils/LocalStorageHelper";
import { getMergedSearchAndHashParams } from "./utils/getMergedSearchAndHashParams";

initHFetch();

let appConfig;

try {
  const appConfigResponse = await hfetch("appConfig.json", {
    cacheBuster: true,
  });
  appConfig = await appConfigResponse.json();
  appConfig.proxy = appConfig.proxy || "";
  appConfig.searchProxy = appConfig.searchProxy || "";

  initFetchWrapper(appConfig);

  const initialURLParams = getMergedSearchAndHashParams();

  let activeMap = initialURLParams.has("m")
    ? initialURLParams.get("m")
    : appConfig.defaultMap;

  const useBackend = appConfig.mapserviceBase?.trim().length > 0;

  const fetchConfig = async () => {
    if (useBackend === false) {
      const simpleConfig = `${initialURLParams.get("m") || "simpleMapAndLayersConfig"}.json`;
      return await hfetch(simpleConfig, {
        cacheBuster: true,
      });
    } else {
      const configUrl = `${appConfig.proxy}${appConfig.mapserviceBase}/config`;
      try {
        return await hfetch(`${configUrl}/${activeMap}`);
      } catch {
        activeMap = appConfig.defaultMap;
        // …and try to fetch again.
        return await hfetch(`${configUrl}/${activeMap}`);
      }
    }
  };

  const [mapConfigResponse, customThemeResponse] = await Promise.all([
    fetchConfig(),
    hfetch("customTheme.json", { cacheBuster: true }),
  ]);

  const mapConfig = await mapConfigResponse.json();
  const customTheme = await customThemeResponse.json();

  const config = {
    activeMap: useBackend ? activeMap : "simpleMapConfig",
    appConfig: appConfig,
    layersConfig: mapConfig.layersConfig,
    mapConfig: mapConfig.mapConfig,
    userDetails: mapConfig.userDetails,
    userSpecificMaps: mapConfig.userSpecificMaps,
    initialURLParams,
  };

  window.webmapPublicApi = {
    maxZoom: config.mapConfig.map.maxZoom,
    minZoom: config.mapConfig.map.minZoom,
  };

  LocalStorageHelper.setKeyName(config.activeMap);

  createRoot(document.getElementById("root")).render(
    <WebMAPThemeProvider
      activeTools={buildConfig.activeTools}
      config={config}
      customTheme={customTheme}
    />
  );
} catch (error) {
  console.error(error);

  const loadErrorTitle = appConfig?.loadErrorTitle || "Възникнала е грешка";
  const loadErrorMessage =
    appConfig?.loadErrorMessage ||
    "Грешка при зареждането на приложението. Можете да опитате да възстановите приложението, като натиснете бутона по-долу. Ако грешката продължава, можете да се свържете със сисадмина.";
  const loadErrorReloadButtonText =
    appConfig?.loadErrorReloadButtonText || "Възстановяване на приложението";

  createRoot(document.getElementById("root")).render(
    <StartupError
      loadErrorMessage={loadErrorMessage}
      loadErrorTitle={loadErrorTitle}
      loadErrorReloadButtonText={loadErrorReloadButtonText}
    />
  );
}
