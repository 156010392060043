import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import FeaturePropFilters from "./FeaturePropsFilters";
import AppModel from "../../models/AppModel.js";

import {
  customComponentsForReactMarkdown, // the object with all custom components
  setOptions, // a method that will allow us to send infoclick options from here to the module that defines custom components
  Paragraph, // special case - we want to override the Paragraph component here, so we import it separately
} from "../../utils/customComponentsForReactMarkdown";
import { isValidUrl } from "../../utils/Validator";

export default class FeaturePropsParsing {
  constructor(settings) {
    this.globalObserver = settings.globalObserver;
    this.options = settings.options;

    setOptions(this.options);

    // Two arrays that will hold pending promises and their resolved values, respectively.
    this.pendingPromises = [];
    this.resolvedPromisesWithComponents = [];

    this.markdown = null;
    this.properties = null; // Will hold the property values from the clicked feature

    // Default to true to ensure backwards compatibility with old configs that predominately use HTML
    this.allowDangerousHtml = this.options.allowDangerousHtml ?? true;

    // Do we want the markdown-renderer to transform the provided uri's or not? Defaults to true to make sure un-safe uri's are not passed by mistake.
    // Disabling the transformer could be used to allow for links to desktop applications, for example app://open.
    this.transformLinkUri = this.options.transformLinkUri ?? true;

    // Let's define which regex to use when looking for placeholders in the infoclick definition. There's the
    // old (and proved) solution and a new one (see #1368). This is an admin setting for now, as this change does
    // affect existing setups.
    this.placeholderMatchingRegex =
      this.options.useNewPlaceholderMatching === true
        ? /{[^}]*}/g // Capture all curly bracket content, see #1277 and #1368
        : /{[\s\w\u00C0-\u00ff@\-|!,'.():]+}/g; // Let's only use the old and well-tested regex

    this.components = {
      ...customComponentsForReactMarkdown,
      p: ({ children }) => {
        if (!children) {
          return null;
        }

        // Fix for #1425
        if (!Array.isArray(children)) {
          children = [children];
        }

        return (
          <Paragraph variant="body2">
            {children.map((child, index) => {
              // Initiate a holder for external components. If a regex matches below,
              // this variable will be filled with correct value.
              let externalComponent = null;

              if (child && typeof child === "string") {
                const match = child.match(/{(\d+)}/);
                if (
                  match &&
                  this.resolvedPromisesWithComponents.hasOwnProperty(match[1])
                ) {
                  // If matched, replace the placeholder with the corresponding component.
                  externalComponent =
                    this.resolvedPromisesWithComponents[match[1]];
                }
              }
              // If externalComponent isn't null anymore, render it. Else, just render the children.
              return (
                <React.Fragment key={index}>
                  {externalComponent || child}
                </React.Fragment>
              );
            })}
          </Paragraph>
        );
      },
    };
  }

  #valueFromJson = (str) => {
    if (typeof str !== "string") return false;
    const jsonStart = /^\[|^\{(?!\{)/;
    const jsonEnds = {
      "[": /]$/,
      "{": /}$/,
    };
    const start = str.match(jsonStart);
    const jsonLike = start && jsonEnds[start[0]].test(str);
    var result = false;

    if (jsonLike) {
      try {
        result = JSON.parse(str);
      } catch (ex) {
        result = false;
      }
    } else {
      result = false;
    }

    return result;
  };

  #getPropertyValueForPlaceholder = (placeholder) => {
    // First strip the curly brackets, e.g. {foobar} -> foobar
    placeholder = placeholder.substring(1, placeholder.length - 1);

    // Placeholders to be fetch from external components will include "@@", and
    // they need to be treated differently from "normal" placeholders (sans @@).
    if (placeholder.includes("@@") && !placeholder.includes("@@@")) {
      // Extract the property and plugins names from the placeholder.
      const [propertyName, pluginName] = placeholder.split("@@");

      // Grab the actual value of this placeholder from the properties collections
      const propertyValue = this.properties[propertyName];

      // If they key was not found in the properties object, or the value is empty, we can't go on.
      if (
        propertyValue === undefined ||
        propertyValue === null ||
        propertyValue.trim() === ""
      ) {
        return "";
      } else {
        return `{${
          this.pendingPromises.push(
            this.#fetchExternal(propertyValue, pluginName)
          ) - 1
        }}`;
      }
    } else if (placeholder.includes("|")) {
      return FeaturePropFilters.applyFilters(this.properties, placeholder);
    }
    // Just a "normal" placeholder, e.g. {foobar}
    else {
      return (
        // "placeholder", but if it does, we can't be sure that it will have the replace() method (as only Strings have it).)
        this.properties?.[placeholder]?.replace?.(/=/g, "&equal;") || // If replace() exists, it's a string, so we can revert our equal signs.
          this.properties[placeholder] != null
          ? this.properties[placeholder]
          : "" // If not a string, return the value as-is…
        // …unless it's undefined or null - in that case, return an empty string.
      );
    }
  };

  #fetchExternal = (propertyValue, pluginName) => {
    // If there are listeners for the current plugin that we parsed out here…
    if (
      this.globalObserver.getListeners(`core.info-click-${pluginName}`).length >
      0
    ) {
      return new Promise((resolve, reject) => {
        // …let's return a Promise that will publish an event to the
        // requested plugin. The listening plugin will use the payload,
        // together with resolve/reject to fulfill the Promise.
        this.globalObserver.publish(`core.info-click-${pluginName}`, {
          payload: propertyValue,
          resolve,
          reject,
        });
      });
    } else {
      return null;
    }
  };

  #conditionalReplacer = (...args) => {
    // Extract the regex named capture groups, they will be the last argument
    // when .replace() calls this helper.
    // Expect matched to contain 'condition', 'attributes' and 'content'.
    const matched = args[args.length - 1];

    // Do different things, depending on 'condition'
    switch (matched.condition) {
      case "if":
        matched.content += "\n";

        if (
          matched.attributes?.includes("=") &&
          !isValidUrl(matched.attributes)
        ) {
          // We allow two comparers: "equal" ("=") and "not equal" ("!=")
          const comparer = matched.attributes.includes("!=") ? "!=" : "=";

          // Turn "FOO=\"BAR\"" into k = "FOO" and v = "BAR"
          const [k, v] = matched.attributes
            .split(comparer) // Create an array by splitting the attributes on our comparer string
            .map((e) => e.replaceAll('"', "").trim()); // Remove double quotes and whitespace

          switch (comparer) {
            // See #669
            case "=":
              // Using truthy equal below: we want 2 and "2" to be seen as equal.
              // eslint-disable-next-line eqeqeq
              if (k == v) {
                return matched.content;
              } else {
                return "";
              }
            // See #1128
            case "!=":
              // Using truthy not equal below: we want '2 is not equal "2"' to evaluate to false.
              // eslint-disable-next-line eqeqeq
              if (k != v) {
                return matched.content;
              } else {
                return "";
              }
            default:
              return "";
          }
        }
        // Handle <if foo> - if it exits, evaluate to true and show content
        else if (matched.attributes?.trim().length > 0) {
          return matched.content;
        }
        // Handle <if > (i.e. do not render because the attribute to evaluate is falsy)
        else {
          return "";
        }
      // For any other condition, leave as-is (could be HTML tag)
      default:
        return args[0];
    }
  };

  #markdownHrefEncoder = (...args) => {
    // The named capture groups will be the last parameter
    const matched = args[args.length - 1];

    // Anchor text and title are simple
    const text = matched.text;
    const title = matched.title ? " " + matched.title : "";

    // Anchor href will require some more work.
    let href = matched.href;

    try {
      // Try creating a new URL from the matched href.
      // Invoking new URL will escape any special characters and ensure
      // that we provide a well-formatted URL to the MarkDown.
      href = new URL(href);
    } catch (error) {
      href = encodeURI(href);
    }

    // Prepare a nice MD Anchor string
    const r = `[${text}](${href}${title})`;
    return r;
  };

  #decorateProperties(prefix, kvData) {
    Object.entries(kvData).forEach(([key, value]) => {
      this.properties[`${prefix}:${key}`] = value;
    });
  }

  /**
   * Converts a JSON-string of properties into a properties object
   * @param {str} properties
   * @returns {object}
   */
  extractPropertiesFromJson = (properties) => {
    Object.keys(properties).forEach((property) => {
      var jsonData = this.#valueFromJson(properties[property]);
      if (jsonData) {
        delete properties[property];
        properties = { ...properties, ...jsonData };
      }
    });
    return properties;
  };

  setMarkdownAndProperties({ markdown, properties }) {
    this.markdown = markdown;
    this.properties = properties;

    this.#decorateProperties("click", AppModel.getClickLocationData());

    return this;
  }

  mergeFeaturePropsWithMarkdown = async () => {
    if (this.markdown && typeof this.markdown === "string") {
      (this.markdown.match(this.placeholderMatchingRegex) || [])
        .map((match) => match.replace("{{if ", "")) // There's a risk that the regex matched "{{if...", see #1368
        .filter((match) => match !== "{{/if}") // Same as above
        .forEach((placeholder) => {
          // placeholder is a string either a basic string, "{foobar}" or a more complicated one
          // such as "{intern_url_1@@documenthandler}" or "{foobar|hasValue("Value exists", "No value found")}"
          // Let's replace all occurrences of the placeholder like this:
          // {foobar} -> Some nice FoobarValue
          // {foobar|hasValue("Value exists", "No value found")} -> Will be taken care of by props filters
          // {intern_url_1@@documenthandler} -> {n} // n is element index in the array that will hold Promises from external components
          this.markdown = this.markdown.replace(
            placeholder,
            this.#getPropertyValueForPlaceholder(placeholder)
          );
        });

      this.markdown = this.markdown.replace(
        /{{(?<condition>\w+)[\s/]?(?<attributes>[^}}]+)?}}(?<content>[^{{]+)?(?:{{\/\1}}\n?)/gi,
        this.#conditionalReplacer
      );

      this.markdown = this.markdown.replace(
        /\[(?<text>[^[]+)\]\((?<href>[^")]+)(?<title>".*")?\)/gm,
        this.#markdownHrefEncoder
      );

      this.markdown = this.markdown.replace(/&equal;/g, "=");

      this.resolvedPromisesWithComponents = await Promise.all(
        this.pendingPromises
      );

      const rehypePlugins = this.allowDangerousHtml ? [rehypeRaw] : [];

      return (
        <ReactMarkdown
          urlTransform={this.transformLinkUri ? undefined : (x) => x} // If transformLinksUri is set to false, we pass a function that simply returns the uri as-is.
          remarkPlugins={[gfm]} // GitHub Formatted Markdown adds support for Tables in MD
          rehypePlugins={rehypePlugins} // Needed to parse HTML, activated in admin
          components={this.components} // Custom renderers for components, see definition in this.components
          children={this.markdown} // Our MD, as a text string
        />
      );
    }
  };
}
