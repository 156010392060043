function getSubLayerNameFromFeatureId(feature, layer) {
  const featureId = feature?.getId();

  let subLayerName = Object.keys(layer.layersInfo).find((id) => {
    const layerName = id.split(":").length === 2 ? id.split(":")[1] : id;
    const fidArray = featureId?.split?.(".") || [];
    for (let i = fidArray.length - 1; i >= 0; i--) {
      if (fidArray.slice(0, i).join(".") === layerName) {
        return true;
      }
    }
    // The layerName from the feature could not be matched against the layerId from config...
    return false;
  });

  if (
    subLayerName === undefined &&
    // This undefined-string check below is a temporary fix to make raster layers work better in legacy infoclick.
    ((typeof featureId === "string" && featureId.startsWith("undefined")) ||
      featureId === "") &&
    layer.subLayers.length === 1
  ) {
    // Let's assume that the layer's name is the name of the first layer
    subLayerName = layer.subLayers[0];

    feature.setId("fakeFeatureIdIssue1090");
  }

  return subLayerName;
}
function getSimpleInfoClickInfoFromLayerConfig(layer) {
  // First we'll make sure a layer with the layerInfo-prop was supplied...
  const layerInfo = layer?.get("layerInfo");
  // If the prop is missing, there's nothing we can do...
  if (!layerInfo) {
    console.error(
      "getSimpleInfoClickInfoFromLayerConfig was invoked with bad parameters. The supplied layer is probably missing the layerInfo-property."
    );
    return {};
  }
  // The display name and infoClickDefinition is called caption and information on the layerInfo-prop...
  const { caption, information } = layerInfo;
  return {
    displayName: caption,
    infoclickDefinition: information,
  };
}

/**
 * @summary Get information needed to properly render the FeatureInfo-window
 * @description Will get the sub-layer from which the supplied feature comes from and
 * extract all necessary information needed to properly render the FeatureInfo-window.
 * Expects:
 *  - OL feature parsed from getFeatureInfo-response.
 *  - OL layer that has been clicked.
 * Returns:
 *  - An object containing information needed to properly render the FeatureInfo-window. All information is parsed from the layer config.
 */
export function getInfoClickInfoFromLayerConfig(feature, layer) {
  if (!feature || !layer) {
    console.error(
      "getInfoClickInfoFromLayerConfig was called with bad parameters."
    );
    return {};
  }
  // If layersInfo is missing, we wont be able to find the info-click-info in "layer.layersInfo"...
  // Luckily, some layers store (more simple) info-click-info on another layer-property, so let's try to find that instead.
  if (!layer.layersInfo) {
    return getSimpleInfoClickInfoFromLayerConfig(layer);
  }
  const subLayerName = getSubLayerNameFromFeatureId(feature, layer);
  // Having just the layer's name as an ID is not safe - multiple
  // WFS's may use the same name for two totally different layers.
  // So we need something more. Luckily, we can use the UID property
  // of our OL layer.
  const layerId = subLayerName + (layer?.ol_uid && "." + layer?.ol_uid);
  // Get caption for this dataset
  // If there are layer groups, we get the display name from the layer's caption.
  const displayName =
    layer?.layersInfo[subLayerName]?.caption ||
    layer?.get("caption") ||
    "Unnamed dataset";
  // Get infoclick definition for this dataset
  const infoclickDefinition = layer?.layersInfo?.[subLayerName]?.infobox || "";
  // Prepare the infoclick icon string
  const infoclickIcon = layer?.layersInfo?.[subLayerName]?.infoclickIcon || "";
  // Prepare displayFields, shortDisplayFields and secondaryLabelFields.
  // We need them to determine what should be displayed
  // in the features list view.
  const displayFields =
    layer?.layersInfo?.[subLayerName]?.searchDisplayName
      ?.split(",")
      .map((df) => df.trim()) || [];
  const shortDisplayFields =
    layer?.layersInfo?.[subLayerName]?.searchShortDisplayName
      ?.split(",")
      .map((df) => df.trim()) || [];
  const secondaryLabelFields =
    layer?.layersInfo?.[subLayerName]?.secondaryLabelFields
      ?.split(",")
      .map((df) => df.trim()) || [];

  return {
    subLayerName,
    layerId,
    displayName,
    infoclickDefinition,
    infoclickIcon,
    displayFields,
    shortDisplayFields,
    secondaryLabelFields,
  };
}
