import AppModel from "../../models/AppModel";
import WebMAPTransformer from "../../utils/WebMAPTransformer";

class PropFilters {
  constructor() {
    this.filters = {};
    this.properties = {};
    return this;
  }

  applyFilters(properties, input) {
    this.properties = properties; // Make properties available for the filters

    let filters = input.split("|");
    const key = filters.shift().trim();

    let value = null;

    if (key.indexOf("'") === 0) {
      value = key.substring(1, key.length - 1);
    } else {
      value = this.properties[key];
      if (!value) {
        value = "";
      }
    }

    const splitArgsRegEx = /^''|'[^']+'|^\d+\.?\d{1,}$|[a-z_0-9]+/gm;

    // Regex to match a property name when testing if it's a nested property.
    const isPropertyRegEx = /^[a-z][a-z_0-9]+$/gm;

    filters.forEach((inFilter) => {
      // This is where we handle chained filters.
      const argsStart = inFilter.indexOf("(");
      let filterName = inFilter;
      let args = [];

      if (argsStart > -1) {
        filterName = inFilter.substr(0, argsStart);
        args = inFilter
          .substring(argsStart + 1, inFilter.lastIndexOf(")"))
          .match(splitArgsRegEx);
        args.forEach((v, i, a) => {
          v = v.trim();
          if (isPropertyRegEx.test(v)) {
            a[i] = this.properties[v] ? this.properties[v].trim() : "";
          } else {
            a[i] = this.freeString(v);
          }
        });
      }

      value = this.execFilter(filterName, value, args);
    });

    return value;
  }

  execFilter(filterName, value, args) {
    const filter = this.get(filterName);

    if (filter) {
      try {
        return filter.func.apply(this, [value, ...args]);
      } catch (err) {
        console.warn(
          `FeaturePropFilters: Could not apply filter '${filterName}' on value ${value} with args ${args},`,
          err
        );
      }
    }

    return value;
  }

  freeString(s) {
    // Free a string contained inside ''
    if (s.indexOf("'") === 0) {
      s = s.substring(1, s.length - 1);
    }
    return s;
  }

  get(key) {
    if (this.filters[key]) {
      return this.filters[key];
    } else {
      console.warn(
        `FeaturePropFilters: Could not find filter with name '${key}'`
      );
    }
    return null;
  }

  add(key, f) {
    this.filters[key] = {
      func: f,
    };
  }

  addПсевдоним(key, targetKey) {
    this.add(key, this.get(targetKey).func);
  }
}

function fixDate(value) {
  if (value.indexOf("-") <= -1) {
    value = `${value.substr(0, 4)}-${value.substr(4, 2)}-${value.substr(6, 2)}`;
  }
  return value;
}

const filters = new PropFilters();

filters.add(
  "roundToDecimals",
  function (value, numDecimals, retOriginalValueIfParamsNotNumbers = 0) {
    if (isNaN(value) || isNaN(numDecimals)) {
      if (parseInt(retOriginalValueIfParamsNotNumbers) === 1) {
        return value;
      } else {
        throw new Error("Arguments should be numbers");
      }
    }
    // We need to double wrap for toLocaleString to work as toFixed returns a string.
    return parseFloat(
      parseFloat(value).toFixed(parseInt(numDecimals))
    ).toLocaleString();
  }
);

filters.add("replace", function (value, replace, withString) {
  return value.replace(new RegExp(replace, "gm"), withString);
});

filters.add("default", function (value, defaultValue) {
  return value === "" ? defaultValue : value;
});
filters.addПсевдоним("fallback", "default");

filters.add("lt", function (value, test, lessValue, greaterValue) {
  if (isNaN(value) || isNaN(test)) {
    return value;
  }
  const val = typeof value === "string" ? parseFloat(value) : value;
  const t = typeof test === "string" ? parseFloat(test) : test;

  if (val < t) {
    return (typeof lessValue === "string" && lessValue.length === 0) ||
      !lessValue
      ? value
      : lessValue;
  } else {
    return (typeof greaterValue === "string" && greaterValue.length === 0) ||
      !greaterValue
      ? value
      : greaterValue;
  }
});

filters.add("gt", function (value, test, greaterValue, lessValue) {
  if (isNaN(value) || isNaN(test)) {
    return value;
  }
  const val = typeof value === "string" ? parseFloat(value) : value;
  const t = typeof test === "string" ? parseFloat(test) : test;

  if (val > t) {
    return (typeof greaterValue === "string" && greaterValue.length === 0) ||
      !greaterValue
      ? value
      : greaterValue;
  } else {
    return (typeof lessValue === "string" && lessValue.length === 0) ||
      !lessValue
      ? value
      : lessValue;
  }
});

filters.add("naNToNum", function (value, num) {
  if (!value || isNaN(value)) {
    return parseFloat(num);
  } else {
    return value;
  }
});

filters.add("hasValue", function (value, trueValue = "", falseValue = "") {
  return value === "" ? falseValue : trueValue;
});

filters.add("equals", function (value, test, trueValue, falseValue) {
  return value === test ? trueValue : falseValue || value;
});

filters.add("notEquals", function (value, test, falseValue, trueValue) {
  return value !== test ? falseValue : trueValue || value;
});

filters.add("datetime", function (value) {
  const date = typeof value === "string" ? new Date(value) : value;
  return date.toLocaleString();
});

filters.add("date", function (value) {
  value = fixDate(value);
  const date = typeof value === "string" ? new Date(value) : value;
  return date.toLocaleDateString();
});

filters.add("time", function (value) {
  const date = typeof value === "string" ? new Date(value) : value;
  return date.toLocaleTimeString();
});

filters.add("dateAddDays", function (value, days) {
  value = fixDate(value);
  const date = typeof value === "string" ? new Date(value) : value;
  date.setDate(date.getDate() + parseFloat(days));
  return date;
});

filters.add("dateAddHours", function (value, hours) {
  value = fixDate(value);
  const date = typeof value === "string" ? new Date(value) : value;
  date.setTime(date.getTime() + parseFloat(hours) * 60 * 60 * 1000);
  return date;
});

filters.add("formatNumber", function (value) {
  if (isNaN(value)) {
    throw new Error("Argument should be a number");
  }
  return Number(value).toLocaleString();
});

filters.add("multiplyBy", function (value, multiplier) {
  if (isNaN(value) || isNaN(multiplier)) {
    throw new Error("Arguments should be numbers");
  }
  return value * multiplier;
});

filters.add("subscript", function (value) {
  let s = value;
  value.match(/\d/gm).forEach((num) => {
    // We'll use unicode chars because html might not be allowed.
    // subscript chars is in order in unicode table
    s = s.replace(num, String.fromCodePoint("0x208" + num));
  });
  return s;
});

filters.add("superscript", function (value) {
  // We'll use unicode chars because html might not be allowed.
  // superscript chars is not in order so we specify these here.
  const sup = [
    "\u2070",
    "\u00B9",
    "\u00B2",
    "\u00B3",
    "\u2074",
    "\u2075",
    "\u2076",
    "\u2077",
    "\u2078",
    "\u2079",
  ];
  let s = value;
  value.match(/\d/gm).forEach((num) => {
    s = s.replace(num, sup[num]);
  });
  return s;
});

filters.add("toUpper", function (value) {
  return value.toUpperCase();
});

filters.add("toLower", function (value) {
  return value.toLowerCase();
});

filters.add("capitalize", function (value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
});

filters.add("substr", function (value, i1, i2) {
  return value.substr(i1, i2);
});

filters.add("substring", function (value, i1, i2) {
  return value.substring(i1, i2);
});

filters.add("left", function (value, searchFor) {
  return value.split(searchFor)[0];
});

filters.add("right", function (value, searchFor) {
  const i = value.indexOf(searchFor);
  return i > -1 ? value.substring(i + searchFor.length, value.length) : value;
});

filters.add("trim", function (value) {
  return value.trim();
});

filters.add(
  "toProjection",
  function (value, xOrY, xProp, yProp, targetProjection, numDecimals = 4) {
    // This is a bit awkward as you need to specify both x and y to get one value.
    // Not fully straight forward...
    const transformer = new WebMAPTransformer({
      projection: AppModel.map.getView().getProjection().getCode(),
    });

    if (isNaN(value)) {
      throw new Error("Value should be a number");
    } else if (!xOrY) {
      throw new Error("Is it 'x' or 'y' you want? Provide as argument.");
    } else if (!xProp || !yProp) {
      throw new Error("Please provide both xProp and yProp");
    } else if (!targetProjection) {
      throw new Error("A target projection is required");
    }

    const coordinates = transformer.getCoordinatesWithProjection(
      Number(this.properties[xProp]),
      Number(this.properties[yProp]),
      targetProjection,
      numDecimals
    );
    return coordinates[xOrY.toLowerCase()];
  }
);

export default filters;
