import React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Link,
  Slide,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  functionalOk,
  setLevel,
  shouldShowNotice,
  thirdPartyOk,
} from "../models/Cookie";

const DEFAULT_MESSAGE =
  "Използваме основни бисквитки, за да гарантираме, че уебсайтът работи добре за вас. Използваме и функционални 'бисквитки', за да ви осигурим възможно най-добрата функционалност, ако сте съгласни с тяхното използване.";
const DEFAULT_URL = "https://bitmap-bulgaria.com/?page_id=3";
const StyledDialog = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    "& .MuiDialog-container": {
      alignItems: "flex-end",
      padding: "16px 20px",
    },
  },
}));

const LabeledCheckbox = ({ checked, disabled, label, onChange }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          disabled={disabled ?? false}
          checked={checked}
          onChange={onChange}
        />
      }
      label={label}
    />
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CookieNotice({ globalObserver, appModel }) {
  const { config } = appModel;

  // We should initialize the dialog:s open-state to whatever the manager states.
  const [open, setOpen] = React.useState(shouldShowNotice());
  const [functionalChecked, setFunctionalChecked] =
    React.useState(functionalOk);
  const [thirdPartChecked, setThirdPartChecked] = React.useState(thirdPartyOk);

  const defaultCookieNoticeMessage =
    config?.mapConfig?.map?.defaultCookieNoticeMessage || DEFAULT_MESSAGE;
  const defaultCookieNoticeUrl =
    config?.mapConfig?.map?.defaultCookieNoticeUrl || DEFAULT_URL;
  const showThirdPartCheckbox =
    config?.mapConfig?.map?.cookieUse3dPart ?? false;

  React.useEffect(() => {
    globalObserver.subscribe("core.showCookieBanner", () => {
      setOpen(true);
    });
    return () => {
      globalObserver.unsubscribe("core.showCookieBanner");
    };
  }, [globalObserver]);

  const handleAllowSelectedClick = React.useCallback(() => {
    let cookieLevel = 1;
    if (functionalChecked) {
      cookieLevel = cookieLevel | 2;
    }
    if (thirdPartChecked) {
      cookieLevel = cookieLevel | 4;
    }
    setLevel(cookieLevel);
    setOpen(false);
  }, [functionalChecked, thirdPartChecked]);

  const handleAllowAllClick = React.useCallback(() => {
    let cookieLevel = 3;
    setFunctionalChecked(true);
    if (showThirdPartCheckbox) {
      cookieLevel = cookieLevel | 4;
      setThirdPartChecked(true);
    }
    setLevel(cookieLevel);
    setOpen(false);
  }, [showThirdPartCheckbox]);

  return (
    <StyledDialog
      sx={{ zIndex: "9999" }}
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="cookie-dialog-content-text"
    >
      <DialogContent>
        <DialogContentText
          sx={{ color: "text.primary" }}
          id="cookie-dialog-content-text"
        >
          {`${defaultCookieNoticeMessage} `}
          <Link
            href={defaultCookieNoticeUrl}
            underline="always"
            rel="noreferrer"
            target="_blank"
          >
            {"Mer information om kakor"}
          </Link>
        </DialogContentText>
      </DialogContent>

      <DialogActions
        sx={{
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <FormGroup
          sx={{
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <LabeledCheckbox
            disabled={true}
            checked={true}
            label={"Необходими"}
          />
          <LabeledCheckbox
            onChange={(event) => {
              setFunctionalChecked(event.target.checked);
            }}
            checked={functionalChecked}
            label={"Функционални"}
          />
          {showThirdPartCheckbox && (
            <LabeledCheckbox
              onChange={(event) => {
                setThirdPartChecked(event.target.checked);
              }}
              checked={thirdPartChecked}
              label={"Трета страна"}
            />
          )}
        </FormGroup>
        <Button
          color="primary"
          variant="contained"
          onClick={handleAllowSelectedClick}
        >
          {"OK"}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleAllowAllClick}
          sx={{ margin: [1, 1] }}
        >
          {"Затвори"}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default CookieNotice;
