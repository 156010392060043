class LocalStorageHelper {
  mapName = "map_options_unknown_map";
  /**
   * @summary We want each map to have its own settings in LS, so we use mapConfig's name
   * as a key for the LS property.
   * @description This will normally be called once, when the map is initiated. All subsequent
   * use (getting and setting values) will read this key's value.
   *
   * @param {string} [mapName="unknown_map"]
   * @memberof LocalStorageHelper
   */
  setKeyName(mapName = "unknown_map") {
    this.mapName = `map_options_${mapName}`;
  }

  getValueFromPath = (obj, path) =>
    path.split(".").reduce((r, k) => r?.[k], obj);

  get(key, defaults = {}, useGlobal = false) {
    try {
      const mapName = useGlobal ? "global_options" : this.mapName;

      // Return a shallow merged objects with
      return {
        ...defaults, // …supplied defaults, that can be overwritten by…
        ...JSON.parse(window.localStorage.getItem(mapName))[
          key // …whatever exists in local storage for the specified key
        ],
      };
    } catch (error) {
      // If parsing failed, return defaults
      return defaults;
    }
  }

  _experimentalGet(key, defaults = {}, useGlobal = false) {
    try {
      const mapName = useGlobal ? "global_options" : this.mapName;

      const mapSettings = JSON.parse(window.localStorage.getItem(mapName));
      return this.getValueFromPath(mapSettings, key) || defaults;
    } catch (error) {
      // If parsing failed, return defaults
      return defaults;
    }
  }

  set(key, value, useGlobal = false) {
    const mapName = useGlobal ? "global_options" : this.mapName;
    window.localStorage.setItem(
      mapName, // Use a map-specific name as key for LocalStorage setting
      JSON.stringify({
        ...JSON.parse(window.localStorage.getItem(mapName)),
        [key]: value,
      })
    );
  }
}

// Export singleton instance
/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new LocalStorageHelper();
