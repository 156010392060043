import GeoJSON from "ol/format/GeoJSON.js";
import buffer from "@turf/buffer";
import union from "@turf/union";
import { transform } from "ol/proj";

class WebMAPTransformer {
  #mapProjection;
  #geoJson;

  constructor(settings) {
    const { projection } = settings;
    if (!projection) {
      throw new Error("Проекция, необходима за WebMAP Transformer");
    }

    this.#mapProjection = projection;

    this.#geoJson = new GeoJSON({
      // Tell GeoJSON that our features will come in the map's projection
      featureProjection: this.#mapProjection,
    });
  }

  getUnion(feature1, feature2) {
    // Create a GeoJSON Feature object by reading our OL Feature.
    // This object will be in WGS84.
    const f1 = this.#geoJson.writeFeatureObject(feature1);
    const f2 = this.#geoJson.writeFeatureObject(feature2);

    // union the 2 incoming geometries.
    const unionFeatureObject = union(f1.geometry, f2.geometry);

    // convert GeoJSON feature object back to OpenLayer Feature using the maps projection.
    const olf = this.#geoJson.readFeature(unionFeatureObject, {
      // Tell the format reader to return features in our map's projection!
      featureProjection: this.#mapProjection,
    });
    olf.setId(Math.random() * 1e20);
    return olf;
  }

  getBuffered(feature, distance) {
    // Create a GeoJSON Feature object by reading our OL Feature.
    // This object will be in WGS84.
    const gjFeatureObject = this.#geoJson.writeFeatureObject(feature);

    const buffered = buffer(gjFeatureObject, distance / 1000, {
      units: "kilometers",
    });
    const olf = this.#geoJson.readFeature(buffered, {
      // Tell the format reader to return features in our map's projection!
      featureProjection: this.#mapProjection,
    });
    olf.setId(Math.random() * 1e20);
    return olf;
  }

  getCoordinatesWithProjection(x, y, targetProjection, numberOfDecimals = 4) {
    const newCoords = transform([x, y], this.#mapProjection, targetProjection);
    return {
      x: parseFloat(newCoords[0].toFixed(numberOfDecimals)),
      y: parseFloat(newCoords[1].toFixed(numberOfDecimals)),
    };
  }
}

export default WebMAPTransformer;
